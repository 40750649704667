<template>
  <div class="wg-cap-wrap">
    <div class="wg-cap-wrap__header">
      <span>请在下图依次点击：</span>
      <img class="wg-cap-wrap__thumb" v-if="thumbBase64Code" :src="thumbBase64Code" alt=" " />
    </div>
    <div class="wg-cap-wrap__body" :style="style">
      <img
        class="wg-cap-wrap__picture"
        v-if="imageBase64Code"
        :src="imageBase64Code"
        alt=" "
        @click="handleClickPos($event)"
      />
      <img
        class="wg-cap-wrap__loading"
        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBzdHlsZT0ibWFyZ2luOiBhdXRvOyBiYWNrZ3JvdW5kOiByZ2JhKDI0MSwgMjQyLCAyNDMsIDApOyBkaXNwbGF5OiBibG9jazsgc2hhcGUtcmVuZGVyaW5nOiBhdXRvOyIgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIj4KICA8Y2lyY2xlIGN4PSI1MCIgY3k9IjM2LjgxMDEiIHI9IjEzIiBmaWxsPSIjM2U3Y2ZmIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN5IiBkdXI9IjFzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgY2FsY01vZGU9InNwbGluZSIga2V5U3BsaW5lcz0iMC40NSAwIDAuOSAwLjU1OzAgMC40NSAwLjU1IDAuOSIga2V5VGltZXM9IjA7MC41OzEiIHZhbHVlcz0iMjM7Nzc7MjMiPjwvYW5pbWF0ZT4KICA8L2NpcmNsZT4KPC9zdmc+"
        alt="正在加载中..."
      />
      <template v-for="(dot, key) in dots">
        <div :key="key" class="wg-cap-wrap__dot" :style="`top: ${dot.y}px; left:${dot.x}px;`">
          <span>{{ dot.index }}</span>
        </div>
      </template>
    </div>
    <div class="wg-cap-wrap__footer">
      <div class="wg-cap-wrap__ico">
        <img @click="handleRefreshEvent" src="../../assets/reStart.png" alt="刷新" />
      </div>
      <div class="wg-cap-wrap__btn">
        <span @click="handleCloseEvent">取消</span>
        <button @click="handleConfirmEvent">确认</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoCaptcha",
  props: {
    value: Boolean,
    width: {
      type: String,
      default: "300px",
    },
    height: {
      type: String,
      default: "240px",
    },
    calcPosType: {
      type: String,
      default: "dom",
      validator: (value) => ["dom", "screen"].includes(value),
    },
    maxDot: {
      type: Number,
      default: 5,
      // validator: value => value > 10
    },
    imageBase64: String,
    thumbBase64: String,
  },
  data() {
    return {
      dots: [],
      imageBase64Code: "",
      thumbBase64Code: "",
    };
  },
  watch: {
    value() {
      this.dots = [];
      this.imageBase64Code = "";
      this.thumbBase64Code = "";
    },
    imageBase64(val) {
      this.dots = [];
      this.imageBase64Code = val;
    },
    thumbBase64(val) {
      this.dots = [];
      this.thumbBase64Code = val;
    },
  },
  computed: {
    style() {
      return `width:${this.width}; height:${this.height};`;
    },
  },
  methods: {
    /**
     * @Description: 处理关闭事件
     */
    handleCloseEvent() {
      this.$emit("close");
      this.dots = [];
      this.imageBase64Code = "";
      this.thumbBase64Code = "";
    },
    /**
     * @Description: 处理刷新事件
     */
    handleRefreshEvent() {
      this.dots = [];
      this.$emit("refresh");
    },
    /**
     * @Description: 处理确认事件
     */
    handleConfirmEvent() {
      this.$emit("confirm", this.dots);
    },
    /**
     * @Description: 处理dot
     * @param ev
     */
    handleClickPos(ev) {
      if (this.dots.length >= this.maxDot) {
        return;
      }
      const e = ev || window.event;
      e.preventDefault();
      const dom = e.currentTarget;

      const { domX, domY } = this.getDomXY(dom);
      // ===============================================
      // @notice 如 getDomXY 不准确可尝试使用 calcLocationLeft 或 calcLocationTop
      // const domX = this.calcLocationLeft(dom)
      // const domY = this.calcLocationTop(dom)
      // ===============================================

      let mouseX =
        navigator.appName === "Netscape"
          ? e.pageX
          : e.x + document.body.offsetTop;
      let mouseY =
        navigator.appName === "Netscape"
          ? e.pageY
          : e.y + document.body.offsetTop;

      if (this.calcPosType === "screen") {
        mouseX = navigator.appName === "Netscape" ? e.clientX : e.x;
        mouseY = navigator.appName === "Netscape" ? e.clientY : e.y;
      }

      // 计算点击的相对位置
      const xPos = mouseX - domX;
      const yPos = mouseY - domY;

      // 转整形
      const xp = parseInt(xPos.toString());
      const yp = parseInt(yPos.toString());

      // 减去点的一半
      this.dots.push({
        x: xp - 11,
        y: yp - 11,
        index: this.dots.length + 1,
      });
      return false;
    },
    /**
     * @Description: 找到元素的屏幕位置
     * @param el
     */
    calcLocationLeft(el) {
      let tmp = el.offsetLeft;
      let val = el.offsetParent;
      while (val != null) {
        tmp += val.offsetLeft;
        val = val.offsetParent;
      }
      return tmp;
    },
    /**
     * @Description: 找到元素的屏幕位置
     * @param el
     */
    calcLocationTop(el) {
      let tmp = el.offsetTop;
      let val = el.offsetParent;
      while (val != null) {
        tmp += val.offsetTop;
        val = val.offsetParent;
      }
      return tmp;
    },
    /**
     * @Description: 找到元素的屏幕位置
     * @param dom
     */
    getDomXY(dom) {
      let x = 0;
      let y = 0;
      if (dom.getBoundingClientRect) {
        let box = dom.getBoundingClientRect();
        let D = document.documentElement;
        x =
          box.left +
          Math.max(D.scrollLeft, document.body.scrollLeft) -
          D.clientLeft;
        y =
          box.top +
          Math.max(D.scrollTop, document.body.scrollTop) -
          D.clientTop;
      } else {
        while (dom !== document.body) {
          x += dom.offsetLeft;
          y += dom.offsetTop;
          dom = dom.offsetParent;
        }
      }
      return {
        domX: x,
        domY: y,
      };
    },
  },
};
</script>

<style>
.wg-cap-wrap {
  padding: 18px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border-radius: 10px;
  border-radius: 10px;
  background: #ffffff;
  -webkit-touch-callout: none;
  user-select: none;
  user-select: none;
  user-select: none;
  user-select: none;
}
.wg-cap-wrap__header {
  display: -webkit-box;
  display: flex;
  display: flexbox;
  display: flex;
  align-items: center;
  align-items: center;
  width: 100%;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
}
.wg-cap-wrap__header span {
  font-size: 16px;
  font-family: PingFangSC-Semibold, 'PingFang SC';
  font-weight: 600;
  white-space: nowrap;
  color: #333333;
  flex-grow: 0;
  line-height: 22px;
}
.wg-cap-wrap__header .wg-cap-wrap__image {
  overflow: hidden;
  border-radius: 5px;
  border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  line-height: 1;
}
.wg-cap-wrap__header .wg-cap-wrap__thumb {
  flex-grow: 1;
  width: 100px;
  text-align: center;
  line-height: 1;
}
.wg-cap-wrap__header .wg-cap-wrap__thumb.wg-cap-wrap__hidden {
  display: none;
}
.wg-cap-wrap__body {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: flexbox;
  display: flex;
  display: flex;
  overflow: hidden;
  margin: auto;
  border-radius: 5px;
  border-radius: 5px;
  border-radius: 5px;
  background: #34383e;
}
.wg-cap-wrap__body .wg-cap-wrap__picture {
  position: relative;
  z-index: 10;
  width: 100%;

  /* height: 100%; */

  /* max-width: 100%; */

  /* max-height: 100%; */

  /* object-fit: cover; */

  /* text-align: center; */
}
.wg-cap-wrap__body .wg-cap-wrap__picture.wg-cap-wrap__hidden {
  display: none;
}
.wg-cap-wrap__body .wg-cap-wrap__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  margin-top: -34px;
  margin-left: -34px;
  width: 68px;
  height: 68px;
  text-align: center;
  line-height: 68px;
}
.wg-cap-wrap__body .wg-cap-wrap__dot {
  position: absolute;
  z-index: 10;
  width: 22px;
  height: 22px;
  border: 2px solid #f7f9fb;
  border-radius: 22px;
  border-radius: 22px;
  border-radius: 22px;
  text-align: center;
  color: #cedffe;
  background: #3e7cff;
  line-height: 20px;
  cursor: default;
}
.wg-cap-wrap__footer {
  display: -webkit-box;
  display: flex;
  display: flexbox;
  display: flex;
  align-items: center;
  align-items: center;
  padding-top: 15px;
  width: 100%;
  height: 40px;
  color: #34383e;
  -webkit-box-align: center;
  -ms-flex-align: center;
}
.wg-cap-wrap__footer .wg-cap-wrap__ico {
  flex: 1;
}
.wg-cap-wrap__footer .wg-cap-wrap__ico img {
  margin: 0 5px;
  width: 20px;
  height: 22px;
  color: #34383e;
  cursor: pointer;
}
.wg-cap-wrap__footer .wg-cap-wrap__btn {
  height: 40px;
}
.wg-cap-wrap__footer .wg-cap-wrap__btn button {
  display: inline-block;
  margin: 0;
  width: 94px;
  height: 37px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, 'PingFang SC';
  font-weight: 500;
  border: none;
  border-radius: 5px;
  border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
  color: #ffffff;
  background: #c9bba1;
  outline: none;
  transition: 0.1s;
  letter-spacing: 2px;
  line-height: 1;
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  user-select: none;
  user-select: none;
}
.wg-cap-wrap__footer .wg-cap-wrap__btn span {
  padding-right: 20px;
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, 'PingFang SC';
  font-weight: 400;
  color: #999999;
  line-height: 22px;
}
</style>
