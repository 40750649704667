<template>
  <div class="go-captcha-wrap">
    <GoCaptchaBtnDialog
      class="go-captcha-btn"
      v-model="captStatus"
      :image-base64="captBase64"
      :thumb-base64="captThumbBase64"
      :width="'100%'"
      :height="'100%'"
      :valshow="valshow"
      :btnKey="key"
      :getCodeVal="getCodeVal"
      @confirm="handleConfirm"
      @refresh="handleRequestCaptCode"
      @validate="$emit('validate')"
      @CloseGetCode="CloseGetCode"
    />
  </div>
</template>

<script>
import GoCaptchaBtnDialog from "./GoCaptchaBtnDialog.vue";
import { getCaptcha, getValidateCode } from "../../api/index.js";
export default {
  name: "captcha",
  components: {
    GoCaptchaBtnDialog,
  },
  props: ["valshow", "phonenumber"],
  data() {
    return {
      // 验证码数据
      needCapt: false,
      popoverVisible: true,
      captBase64: "",
      captThumbBase64: "",
      captKey: "",
      captStatus: "default", //控制状态
      captExpires: 0,
      captAutoRefreshCount: 0,
      key: 1,
      getCodeVal: "获取验证码",
      messageShow: null,
      reqKey: true,
    };
  },
  created() {},
  methods: {
    //触发校验并进行相应操作
    CloseGetCode() {
      if (this.key && this.key--) {
        let time = 60;
        this.getCodeVal = `${time--}S后重发`;
        let timer = setInterval(() => {
          this.getCodeVal = `${time--}S后重发`;
          if (time < 0) {
            clearInterval(timer);
            this.getCodeVal = `重新发送`;
            this.key++;
          }
        }, 1000);
      }
    },
    /**
     * 处理请求验证码
     */
    handleRequestCaptCode() {
      this.captBase64 = "";
      this.captThumbBase64 = "";
      this.captKey = "";
      if (!this.reqKey) {
        setTimeout(() => {
          this.getChart();
          this.reqKey = true;
        }, 1000);
      } else {
        this.getChart();
      }
    },
    getChart() {
      this.messageShow && this.messageNow.close();
      getCaptcha({
        mobile: this.phonenumber,
      }).then((response) => {
        const { data = {} } = response.data;
        if ((data["code"] || 0) === 0) {
          if (!data) {
            return;
          }
          this.captBase64 = data["img"] || "";
          this.captThumbBase64 = data["thumbnail"] || "";
          this.captKey = data["captcha_key"] || "";
        } else {
          this.messageShow = this.$message({
            message: `获取人机验证数据失败`,
            type: "warning",
          });
        }
      });
    },
    /**
     * 处理验证码校验请求
     */
    handleConfirm(dots) {
      this.messageShow && this.messageNow.close();
      if (dots.length <= 0) {
        this.messageShow = this.$message({
          message: `请进行人机验证再操作`,
          type: "warning",
        });
        return;
      }

      let dotArr = [];
      dots.forEach((dot) => {
        dotArr.push(dot.x, dot.y);
      });

      getValidateCode({
        captcha: dotArr.join(","),
        mobile: this.phonenumber,
      }).then((response) => {
        const { data = {} } = response;

        if ((data["code"] || 0) === 0) {
          this.messageShow = this.$message({
            message: `人机验证成功`,
            type: "success",
          });
          this.captStatus = "success";
          this.CloseGetCode();
          this.captAutoRefreshCount = 0;
        } else {
          if (this.captAutoRefreshCount > 3) {
            this.$message.close();
            this.captAutoRefreshCount = 0;
            this.captStatus = "over";
            this.messageShow = this.$message({
              message: `错误次数过多`,
              type: "warning",
            });
            this.CloseGetCode();
            return;
          } else {
            this.messageShow = this.$message.error("人机验证失败");
            this.reqKey = false;
          }
          this.handleRequestCaptCode();
          this.captAutoRefreshCount += 1;
          this.captStatus = "error";
        }
      });
    },
  },
};
</script>

<style>
.go-captcha-wrap {
  width: 100%;
  height: 100%;
}
.againSend {
}
</style>
