<template>
  <div class="wg-cap-btn" :style="style">
    <div class="wg-cap-btn__inner wg-cap-active__default">
      <!-- wg-cap-active__default wg-cap-active__error wg-cap-active__over wg-cap-active__success -->
      <template>
        <div @click="handleBtnEvent" class="wg-cap-state__default">
          <!-- 初始状态 -->
          <div class="wg-cap-state__inner">
            <span class="wg-cap-btn__text">{{getCodeVal}}</span>
          </div>
        </div>
      </template>
      <div :class="['selectVail-bag',{'show':popoverVisible}]">
        <div :class="['selectVail-content']">
          <go-captcha
            v-model="popoverVisible"
            width="247px"
            height="244px"
            :max-dot="maxDot"
            :image-base64="imageBase64"
            :thumb-base64="thumbBase64"
            @close="handleCloseEvent"
            @refresh="handleRefreshEvent"
            @confirm="handleConfirmEvent"
            v-show="isShow"
          />
          <!-- <div class="tip" v-show="!isShow">
            <div class="tipTitle">失败次数过多</div>
            <button @click="close">确定</button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoCaptcha from "./GoCaptcha";
export default {
  name: "GoCaptchaBtnDialog",
  components: { GoCaptcha },
  props: {
    getCodeVal: {
      type: String,
      default: "获取验证码",
    },
    btnKey: {
      type: Number,
      default: 1,
    },
    valshow: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "default",
      validator: (value) =>
        ["default", "check", "error", "over", "success"].indexOf(value) > -1,
    },
    width: String,
    height: String,
    maxDot: {
      type: Number,
      default: 5,
    },
    imageBase64: String,
    thumbBase64: String,
  },
  data() {
    return {
      popoverVisible: false,
      captStatus: "default",
      isShow: true,
    };
  },
  watch: {
    popoverVisible(val) {
      if (val) {
        this.captStatus = "check";
        this.$emit("refresh");
      } else if (this.captStatus === "check") {
        this.captStatus = this.value;
      }
    },
    value(val) {
      if (this.captStatus !== "check") {
        this.captStatus = val;
      }
      if (val === "success") {
        setTimeout(() => {
          this.popoverVisible = false;
        }, 0);
      }
      if (val === "over") {
        this.popoverVisible = false;
      }
    },
    captStatus(val) {
      if (val !== "check" && this.value !== val) {
        this.$emit("input", val);
      }
    },
  },
  computed: {
    style() {
      return `width:${this.width}; height:${this.height};`;
    },
  },
  methods: {
    // 用户操作
    async handleBtnEvent() {
      await this.$emit("validate");
      //手机号校验失败或倒计时中禁止触发
      if (!this.valshow || !this.btnKey) return false;
      setTimeout(() => {
        this.popoverVisible = true;
      }, 0);
    },
    close() {
      this.popoverVisible = false;
      setTimeout(() => {
        this.isShow = true;
      }, 800);
      this.$emit("CloseGetCode");
    },
    handleRefreshEvent() {
      this.captStatus = "check";
      this.$emit("refresh");
    },
    handleConfirmEvent(data) {
      this.$emit("confirm", data);
    },
    handleCloseEvent() {
      this.popoverVisible = false;
    },
  },
};
</script>

<style>
/* 弹框 */

.selectVail-bag {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 25, 28, 0.6);
  transition: 0.8s;
  opacity: 0;
}
.selectVail-content {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  margin: auto;
  width: 285px;
  height: 382px;
  background-color: #333333;
  transition: 0.3s;
}
.tipStyle {
  width: 150px;
  height: 50px;
}
.tip {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
}
.tipTitle {
  width: 100%;
  padding: 5px;
  color: red;
  font-size: 14px;
}
.tip button {
  border: none;
  outline: none;
  background-color: white;
  width: 100%;
}

.show {
  opacity: 1;
  z-index: 2;
}
.wg-cap-btn__text {
}
.wg-cap-btn {
  width: 100%;
}
.wg-cap-btn .wg-cap-btn__inner {
  width: 100%;
  height: 100%;
  position: relative;
  letter-spacing: 1px;
}
.wg-cap-btn .wg-cap-state__default,
.wg-cap-btn .wg-cap-state__check,
.wg-cap-btn .wg-cap-state__error,
.wg-cap-btn .wg-cap-state__success,
.wg-cap-btn .wg-cap-state__over {
  position: absolute;
  right: 0px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  user-select: none;

  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  visibility: hidden;
}
.wg-cap-btn .wg-cap-state__default {
}
.wg-cap-btn .wg-cap-state__check {
}
.wg-cap-btn .wg-cap-state__error {
}
.wg-cap-btn .wg-cap-state__over {
}
.wg-cap-btn .wg-cap-state__success {
}
/* 显示切换 */
.wg-cap-btn .wg-cap-active__default .wg-cap-state__default,
.wg-cap-btn .wg-cap-active__error .wg-cap-state__error,
.wg-cap-btn .wg-cap-active__over .wg-cap-state__over,
.wg-cap-btn .wg-cap-active__success .wg-cap-state__success,
.wg-cap-btn .wg-cap-active__check .wg-cap-state__check {
  visibility: visible;
}
.wg-cap-btn .wg-cap-state__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.wg-cap-btn .wg-cap-state__inner em {
  padding-left: 5px;
  color: #3e7cff;
  font-style: normal;
}
.wg-cap-btn .wg-cap-btn__inner .wg-cap-btn__ico {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  font-size: 14px;
  display: inline-block;
  float: left;
  flex: 0;
}
.wg-cap-btn .wg-cap-btn__inner .wg-cap-btn__ico img {
  width: 24px;
  height: 24px;
  float: left;
  position: relative;
  z-index: 10;
}
@keyframes ripple {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.05;
  }
  20% {
    opacity: 0.35;
  }
  65% {
    opacity: 0.01;
  }
  100% {
    transform: scaleX(2) scaleY(2);
    opacity: 0;
  }
}
@-webkit-keyframes ripple {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.05;
  }
  20% {
    opacity: 0.35;
  }
  65% {
    opacity: 0.01;
  }
  100% {
    transform: scaleX(2) scaleY(2);
    opacity: 0;
  }
}
.wg-cap-btn .wg-cap-btn__inner .wg-cap-btn__verify::after {
  background: #409eff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;

  animation: ripple 1.3s infinite;
  -moz-animation: ripple 1.3s infinite;
  -webkit-animation: ripple 1.3s infinite;
  animation-delay: 2s;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
}

.wg-cap-tip {
  padding: 50px 20px 100px;
  font-size: 13px;
  color: #76839b;
  text-align: center;
  line-height: 180%;
  width: 100%;
  max-width: 680px;
}
</style>
