<!--获取会员界面 -- 刘伟成 --7.9日，-->
<template>
  <div class="getMember flex flex-column align-items-center">
    <div class="background">
      <img src="../assets/getMember/bgcround.png" alt />
    </div>
    <div class="getMember-theme">
      <img src="../assets/memberCard.png" alt="我是介绍图" />
    </div>
    <div class="getMember-content flex flex-column align-items-center">
      <div class="getMember-avater">
        <img :src="resData.avatar" alt="我是头像" />
      </div>
      <p>{{ resData.username }}邀请你加入{{activateTitle}}</p>
      <router-view :resdata="resData"></router-view>
      <div class="user-msg">
        <form ref="formEl" action="javascript:;">
          <div class="phone-number">
            <input
              ref="inputPhone"
              :class="{'fontColor' : phoneNumber.trim() !=''}"
              type="text"
              placeholder="请输入您的手机号"
              v-model="phoneNumber"
            />
          </div>
          <div class="validate-code">
            <input
              ref="inputCode"
              :class="{'fontColor' : codeNumber.trim() !=''}"
              type="text"
              placeholder="请输入验证码"
              v-model="codeNumber"
              maxlength="5"
              size="10"
            />
            <div class="selectVaildate">
              <captcha @validate="validate('phone')" :valshow="valshow" :phonenumber="phoneNumber"></captcha>
            </div>
          </div>
          <button class="ButtonOne" @click="getMemberCard()">获取我的会员卡</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getActive, getMember, getBonus, getResource } from "@/api/index";
import captcha from "../components/captcha/captcha.vue";
export default {
  data: function () {
    return {
      formEl: "",
      resData: {},
      phoneNumber: "",
      codeNumber: "",
      phoneValidate: /^1[3-9]\d{9}$/,
      getMemberType: 0,
      messageNow: null,
      valshow: false,
      lessonType: 0,
      activateTitle: "教研室参加活动",
    };
  },
  methods: {
    validate(type) {
      let { phoneValidate, phoneNumber, codeNumber } = this;
      this.messageNow && this.messageNow.close();
      if (!phoneValidate.test(phoneNumber)) {
        this.messageNow = this.$message.error("请检查手机号格式哦!");
        setTimeout(() => {});
        this.animateShaking();
        this.valshow = false;
        return false;
      }
      this.valshow = true;
      if (type == "code" && codeNumber.trim() == "") {
        this.messageNow = this.$message.error("请检查验证码式哦!");
        this.animateShaking();
        return false;
      }
      return true;
    },
    getMemberCard() {
      if (this.validate("code")) {
        let data = {
          TargetId: this.resData.id,
          mobile: this.phoneNumber,
          code: this.codeNumber,
          userId: this.resData.userId,
          type: this.getMemberType,
          lessonType: this.lessonType,
        };
        getMember(data).then((res) => {
          if (res.data.code == 1404) {
            this.$message.error("验证码错误或已经失效!");
            return;
          }
          let data = res.data.data;
          let isGet = data.account === "" ? true : false;
          this.$router.push({
            name: "cardPwd",
            query: {
              isGet,
              account: data.account,
              pwd: data.password,
            },
          });
        });
      }
    },
    close() {
      this.$router.go(-1);
    },
    animateShaking() {
      let Oform = this.$refs.formEl;
      let num = 8;
      let deircation = 1;
      Oform.style.marginRight = `${num--}px`;
      let animate = setInterval(() => {
        if (num < 0) {
          clearInterval(animate);
          return;
        }
        deircation *= -1;
        Oform.style.marginRight = `${deircation * num--}px`;
      }, 50);
    },
  },
  components: {
    captcha,
  },
  created: async function () {
    let query = this.$route.query;
    let req;
    let that = this;
    let path = this.$route.path.split("/")[2];
    switch (path) {
      case "activity":
        req = getActive;
        that.getMemberType = 1;
        break;
      case "limitWelfare":
        req = getBonus;
        that.getMemberType = 2;
        break;
      case "getResource":
        req = getResource;
        that.getMemberType = 2;
        that.activateTitle = "教研室领取资源";
    }
    this.resData = (await req(query)).data.data;
    if (path === "getResource") {
      this.lessonType = this.resData.type;
    }
    switch (that.resData.type) {
      case 1:
        that.resData.typeName = "课程教案";
        break;
      case 2:
        that.resData.typeName = "习题";
        break;
      case 3:
        that.resData.typeName = "知识点";
        break;
      case 4:
        that.resData.typeName = "视频";
        break;
    }
  },
  mounted: function () {},
};
</script>

<style scoped>
.getMember {
  margin-top: 40px;
  width: 100vw;
  min-height: 812px;
  height: 100vh;
}

.getMember > .background {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 300px;
  overflow: hidden;
  background-color: orange;
  z-index: -1;
}
.getMember > .background > img {
  width: 100%;
  height: 100%;
  transform: scaleX(1.1);
}
.getMember-theme {
  flex-grow: 0;
  padding: 6px 20px 14px;
  border-radius: 6xp;
}
.getMember-theme > img {
  width: 334px;
  height: 172px;
}
.getMember-content {
  flex-grow: 1;
  width: 100%;
  padding: 18px 24px 0px;
  background-color: #1a332b;
}
.getMember-content > .getMember-avater {
  margin: 0 auto;
  text-align: center;
}
.getMember-content > .getMember-avater > img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
.getMember-content > p {
  margin: 10px 0px 16px;
  padding-bottom: 2px;
  width: 340px;
  min-height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  line-height: 20px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
}
.getMember-content > .user-msg > form {
  display: grid;
  margin-top: 20px;
  row-gap: 10px;
  justify-items: center;
  transition: 0.05s;
}
.getMember-content > .user-msg > form > div {
  position: relative;
  padding: 10px 10px;
  width: 328px;
  height: 40px;
  background: #f1f3f8;
  border-radius: 6px;
}
.getMember-content > .user-msg > form > div input {
  outline: none !important;
  width: 100%;
  border: none;
  background: #f1f3f8;
  font-size: 14px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #999999;
}
.getMember-content > .user-msg > form > div .selectVaildate {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 10px;
  width: 78px;
  height: 100%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  user-select: none;
}
.getMember-content > .user-msg > form > .ButtonOne {
  margin: 26px 0px 60px;
}
.fontColor {
  color: #333333 !important;
}
</style>
